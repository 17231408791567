
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiMerchant } from "@/core/api";
import { MBTaggings } from "magicbean-saas-common";

import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}

export default defineComponent({
  name: "merchants-fulfillment",
  components: {
    MBTaggings,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref<boolean>(false);
    const loadingPath = ref<boolean>(false);
    const verifyPath = ref<HTMLElement | null>(null);

    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const savePathButton = ref<HTMLElement | null>(null);

    const basicTaggingsRef = ref<null | HTMLFormElement>(null);
    const germanyTaggingsRef = ref<null | HTMLFormElement>(null);
    const chinaTaggingsRef = ref<null | HTMLFormElement>(null);
    const hk_macauTaggingsRef = ref<null | HTMLFormElement>(null);
    const warehouseTaggingsRef = ref<null | HTMLFormElement>(null);

    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      merchant_id: route.params.id,
      service_status: 0,
      fulfillment_b2b_order: 0,
      fulfillment_affiliate_order: "",
      bc_declaration_1210: "",
      bc_declaration_9610: "",
      cc_declaration: "",
      general_trade_declaration: "",
      order_management: "",
      eu_shipment: "",
      transit_qc_warehouse: "",
      overseas_shipment: "",
      export_custom_declaration: "",
      import_custom_declaration: "",
      bonded_warehouse: "",
      cn_shipment: "",
      exception_handling: "",
      tagging_data: [],
    });

    const rules = ref({});

    const pathData = ref([]);
    const path1210NodeMap = ref({});
    const path_9610TableData = ref([]);
    const path9610NodeMap = ref({});

    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums: string[] = [];
      columns.forEach((column, index) => {
        if (index !== 2 && index !== 3) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => {
          const duration = index == 2 ? item?.duration_min : item?.duration_max;
          return Number(duration);
        });
        sums[index] = `${values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0)}`;
      });
      return sums;
    };

    const getPathTableData = async () => {
      const { data } = await ApiMerchant.getMerchantPathList({
        merchant_id: route.params.id,
      });
      pathData.value = data.data;
    };

    const updatePathTable = (key, table) => {
      loadingPath.value = true;
      verifyPath.value = key;
      let verify = true;
      try {
        table.forEach((item, index) => {
          if (item.duration_min === "" || item.duration_max === "") {
            console.log(item);
            throw Error();
          }
        });
      } catch (e) {
        verify = false;
      }
      if (verify) {
        ApiMerchant.updateMerchantPath({
          merchant_id: route.params.id,
          path_key: key,
          rows: table,
        })
          .then(({ data }) => {
            loadingPath.value = false;
            console.log(data);
            if (data.code == 0) {
              console.log(data);
              showFormSubmitSuccessMsg();
            } else {
              showServerErrorMsg(data);
            }
          })
          .catch((error) => {
            console.log(error);
            showServerErrorMsg(error);
          });
      } else {
        loadingPath.value = false;
        showValidateErrorMsg();
      }
    };

    const short_key = [
      "merchant_fulfillment_spec_to_germany",
      "merchant_fulfillment_spec_to_china",
      "merchant_fulfillment_spec_to_hk_macau",
      "merchant_fulfillment_spec_warehouse",
    ];

    const to_germanySource = ref([]);
    const to_germanyValues = ref([]);
    const to_germanyConfig = ref({});
    const to_chinaSource = ref([]);
    const to_chinaValues = ref([]);
    const to_chinaConfig = ref({});
    const to_hk_macauSource = ref([]);
    const to_hk_macauValues = ref([]);
    const to_hk_macauConfig = ref({});
    const warehouseSource = ref([]);
    const warehouseValues = ref([]);

    const getTaggingDataSource = () => {
      return ApiBase.getTaggingGroupData({ short_key: short_key })
        .then(({ data }) => {
          if (data.code == 0) {
            to_germanySource.value =
              data.data.merchant_fulfillment_spec_to_germany.items;
            to_germanyConfig.value =
              data.data.merchant_fulfillment_spec_to_germany.options;
            to_chinaSource.value =
              data.data.merchant_fulfillment_spec_to_china.items;
            to_chinaConfig.value =
              data.data.merchant_fulfillment_spec_to_china.options;
            to_hk_macauSource.value =
              data.data.merchant_fulfillment_spec_to_hk_macau.items;
            to_hk_macauConfig.value =
              data.data.merchant_fulfillment_spec_to_hk_macau.options;
            warehouseSource.value =
              data.data.merchant_fulfillment_spec_warehouse.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getMerchantFulfillmentData = async () => {
      loading.value = true;
      const { data } = await ApiMerchant.getMerchantFulfillmentData({
        merchant_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        to_germanyValues.value =
          data.data.tagging_data.merchant_fulfillment_spec_to_germany?.items ||
          [];
        to_chinaValues.value =
          data.data.tagging_data.merchant_fulfillment_spec_to_china?.items ||
          [];
        to_hk_macauValues.value =
          data.data.tagging_data.merchant_fulfillment_spec_to_hk_macau?.items ||
          [];
        warehouseValues.value =
          data.data.tagging_data.merchant_fulfillment_spec_warehouse?.items ||
          [];
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          let taggingArr: Array<TaggingValue> = [];

          let basicData = basicTaggingsRef.value?.formData;
          let basicitems: Array<TaggingValueItem> = [];
          for (let item in basicData) {
            basicitems.push({
              short_key: item,
              value: basicData[item],
            });
          }
          let basicObj: TaggingValue = {
            short_key: "merchant_pim_spec_basic_segmentation",
            items: basicitems,
          };
          taggingArr.push(basicObj);

          let germanyData = germanyTaggingsRef.value?.formData;
          let germanyitems: Array<TaggingValueItem> = [];
          for (let item in germanyData) {
            germanyitems.push({
              short_key: item,
              value: germanyData[item],
            });
          }
          let germanyObj: TaggingValue = {
            short_key: "merchant_fulfillment_spec_to_germany",
            items: germanyitems,
          };
          taggingArr.push(germanyObj);

          let chinaData = chinaTaggingsRef.value?.formData;
          let chinaitems: Array<TaggingValueItem> = [];
          for (let item in chinaData) {
            chinaitems.push({
              short_key: item,
              value: chinaData[item],
            });
          }
          let chinaObj: TaggingValue = {
            short_key: "merchant_fulfillment_spec_to_china",
            items: chinaitems,
          };
          taggingArr.push(chinaObj);

          let hk_macauData = hk_macauTaggingsRef.value?.formData;
          let hk_macauitems: Array<TaggingValueItem> = [];
          for (let item in hk_macauData) {
            hk_macauitems.push({
              short_key: item,
              value: hk_macauData[item],
            });
          }
          let hk_macauObj: TaggingValue = {
            short_key: "merchant_fulfillment_spec_to_hk_macau",
            items: hk_macauitems,
          };
          taggingArr.push(hk_macauObj);

          let warehouseData = warehouseTaggingsRef.value?.formData;
          let warehouseitems: Array<TaggingValueItem> = [];
          for (let item in warehouseData) {
            warehouseitems.push({
              short_key: item,
              value: warehouseData[item],
            });
          }
          let warehouseObj: TaggingValue = {
            short_key: "merchant_fulfillment_spec_warehouse",
            items: warehouseitems,
          };
          taggingArr.push(warehouseObj);
          loading.value = true;
          if (submitButton.value) {
            // submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiMerchant.updateMerchantFulfillmentData({
            ...formData.value,
            tagging_data: taggingArr,
          });
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg();
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getPath1210NodeLabel = (row, column, cellValue, index) => {
      return path1210NodeMap.value[cellValue];
    };

    const getPath9610NodeLabel = (row, column, cellValue, index) => {
      return path9610NodeMap.value[cellValue];
    };

    const backToList = () => {
      if (route.path.indexOf("offline-merchants") != -1) {
        router.push("/merchant-network/offline-merchants");
      } else {
        router.push("/merchants");
      }
    };

    onBeforeMount(() => {
      getMerchantFulfillmentData();
      getTaggingDataSource();
      getPathTableData();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      loading,
      loadingPath,
      verifyPath,
      formRef,
      submitButton,
      savePathButton,
      basicTaggingsRef,
      germanyTaggingsRef,
      chinaTaggingsRef,
      hk_macauTaggingsRef,
      warehouseTaggingsRef,
      to_germanySource,
      to_germanyValues,
      to_chinaSource,
      to_chinaValues,
      to_hk_macauSource,
      to_hk_macauValues,
      to_germanyConfig,
      to_chinaConfig,
      to_hk_macauConfig,
      warehouseSource,
      warehouseValues,
      formData,
      rules,
      pathData,
      path_9610TableData,
      getSummaries,
      updatePathTable,
      submit,
      getPath1210NodeLabel,
      getPath9610NodeLabel,
      backToList,
    };
  },
});
